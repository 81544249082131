html,
body {
    background-color: #ffffff;
}

main {
    margin-left: 24px;
}

.header-container {
    background-color: #121212;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.topImg,
.bottomImg {
    margin: 20px;
}

.header-container h1 {
    font-size: 32px;
    margin-bottom: 5px;
}

.detailsHeader {
    color: #ffffffb3;
    font-size: 14px;
    font-weight: 500;
}

.header-container img {
    max-width: 100%;
    height: auto;
    display: block;
}

.img-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
}

.play-banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play-icon {
    fill: white;
    font-size: 2em;
}

.movieGenre>span {
    display: inline-block;
    border: 1px solid #ffffffb3;
    border-radius: 20px;
    padding: 5px;
    margin-right: 10px;
}

.header-container svg {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-right: 5px;
}

.bottomImg>span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.header-container .bottomImg .rating {
    font-size: 16px;
}

.afterRating {
    font-size: 12px;
    color: #ffffffb3;
}

.bottomImg .label {
    font-weight: bold;
}

.labelItems,
.labelItems>span {
    color: #4a7fc2;
    font-weight: 400;
    margin-left: 10px;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #121212;
    padding: 5px;
    border: none;
    outline: none;
    width: 90%;
    max-width: 800px;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.75); /* Fond sombre semi-transparent */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-header {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #121212;
    color: white;
}

.close-icon {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.close-icon svg {
    width: 20px;
    height: 20px;
    fill: #555;
}

.close-text {
    cursor: pointer;
}

.awards {
    margin: 24px 24px 24px 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #F5C518;
    border-radius: 5px;
}

.metacritic-score {
    background-color: #F5C518;
    display: block;
    padding: 12px 12px 8px 12px;
    font-size: 16px;
}

.awards-details {
    background-color: #ffffff;
    display: block;
    padding: 12px 12px 8px 12px;
    font-weight: 400;
    font-size: 16px;
}

.mainActors {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .actor-container {
    text-align: center;
  }
  
  .actor-container img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    display: block;
     margin: 0 auto 10px auto;
  }
  
  .actor-container:hover img {
    transform: scale(1.1);
    
  }
  
  .actor-container span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }

  .swiper-wrapper {
    margin-top:10px;
  }
  

@media (min-width: 1200px) {
    .img-container {
        align-items: end;
    }

    .img-container img {
        width: 33.33%;
    }

    .video-container {
        width: 50%;
        padding-bottom: 28.25%;
        height: 0;
        position: relative;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}