.favorite-movie {
    color: white;
    background-color: #121212;
    padding: 0.75em;
}

.favorite-movie h1 {
    position: relative;
    padding-left: 10px;
}

.favorite-movie h1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #F5C518;
    border-radius: 4px;
}

.favorite-movie p {
    color: #B8B6AE;
    font-weight: 500;
    margin: 0.5em 0 2em 0;
}

.fakemovie-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-wrap: nowrap;
}

.fakemovie-card, .empty-card {
    background-color: #1A1A1A;
    position: relative;
    overflow: hidden;
    padding: 10px;
    width: 159px;
}

.fakemovie-card-img, .empty-card-top {
    height: 264px;
    width: 100%;
    background-color: #2A2A2A;
    margin-bottom: 10px;
}

.fakemovie-card-title {
    height: 36px;
    width: 100%;
    background-color: #2A2A2A;
    margin-bottom: 10px; 
}

.fakemovie-card-button {
    height: 36px;
    width: 100%;
    background-color: #2A2A2A;
}

.empty-card-top {
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-card-bottom {
    height: 82px;
    width: 100%;
    background-color: #2A2A2A;
    color: #B8B6AE;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonRemoveFavorite {
    height: 36px;
    width: 160px;
    background-color: #2C2C2C;
    /* color: #ca4f4f; */
    color: #fff;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-self: center;
    align-items: center;
    margin: auto auto 10px auto;
    cursor: pointer;
}

.buttonRemoveFavorite:hover {
    background-color: #432222;
}

.buttonRemoveFavorite svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    fill: #ca4f4f;
}