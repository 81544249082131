html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;  
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

.app {
  margin-top: 50px;
}
