* {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #121212;
    display: flex;
    justify-content: space-between;
    height: 45px;
    width: 100%;
    padding: 5px 5px 5px 10px;
}

.navbar button,
.navbar input {
    border: none;
}

.hamburger {
    flex: 0.5;
    background-color: #121212;
}

.hamburger svg {
    width: 24px;
    height: 24px;
    fill: white;
}

.logo {
    max-width: 50px;
}

.logo img {
    height: 100%;
}

/* avec .useappli */
.login {
    color: white;
    border: none;
    background: transparent;
    white-space: nowrap;
    cursor: pointer;
}

/* sans .useappli */
.login {
    flex: 1;
    width: 200px;
    max-width: 200px;
    color: black;
    background-color: #F5C518;
    border-radius: 8px;
    margin-right: 15px;
    cursor: pointer
}

.search-icon svg {
    fill: white;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.search-icon {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
}

.useAppli {
    flex: 1.2;
    color: black;
    background-color: #F5C518;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer
}

.search-container input {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    color: white;
    background-color: #121212;
    border: none;
}

input:focus {
    outline: none;
}

input::placeholder {
    font-size: 24px;
}

input {
    font-size: 24px;
}

@media (max-width: 800px) {
    .navbar {
        justify-content: space-between;
    }
    .login {    
        flex: 0 1 auto;
    }
}