.bestChoice {
    color: white;
    background-color: #121212;
    padding: 0.75em;
}

.bestChoice h1 {
    position: relative;
    padding-left: 10px;
}

.bestChoice h1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #F5C518;
    border-radius: 4px;
}

.bestChoice p {
    color: #B8B6AE;
    font-weight: 500;
    margin: 0.5em 0 2em 0;
}

.movie-card {
    background-color: #1A1A1A;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 179px;
}

.movie-card h2 {
    height: 20px;
    font-size: 16px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: calc(100% - 20px);
}

.movie-card img {
    height: 264px;
    object-fit: cover;
    margin-bottom: 5px;
    width: 100%;
}

.svg-container {
    height: 36px;
    margin: 5px;
}

.svg-container svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.svg-container span {
    color: #B8B6AE;
    font-size: 16px;
    font-weight: 500;
    margin: 5px 20px 5px 5px;
}

.svg-container button {
    border: none;
    background-color: #1A1A1A;
    width: 36px;
}

.svgYellow {
    fill: #F5C518
}

.svgBlue {
    fill: #5799ef;
    margin-left: 8.5px;
}

.svgBlueEmpty {
    fill: #5799ef;
}

.buttonAddFavorite {
    cursor: pointer;
}

.buttonAddFavorite:hover {
    background-color: #223043;
    border-radius: 6px;
}

.svgAddFavorite {
    fill: #5799ef;
    height: 24px;
    width: 24px;

}

.buttonShowDetails {
    height: 36px;
    width: 160px;
    background-color: #1A1A1A;
    color: #B8B6AE;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 10px auto;
    cursor: pointer;
}

.buttonShowDetails:hover {
    background-color: #2C2C2C;
}

.swiper-slide {
    width: auto;
}