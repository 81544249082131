.ReactModal__Overlay {
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10;
}

.auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.auth-modal main {
  margin: 0 auto;
  max-width: 80%;
  text-align: center;
  height: 60vh;
}

.container {
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  position: relative;
  overflow: hidden;
  width: 768px;
  min-height: 480px;
  color: #000;
}

.container p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin: 20px 0;
}

.container span {
  font-size: 12px;
}

.container a {
  color: #333;
  font-size: 13px;
  text-decoration: none;
  margin: 15px 0 10px;
}

.container button {
  background-color: #F5C518;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}

.container button.hidden {
  border-color: black;
}

.form {
  background-color: #fff;
  display: flex;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
}

.container input {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  margin: 8px 0;
  padding: 10px 15px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}

.container input::placeholder {
  font-size: 16px;
  font-weight: 400;
}

input {
  font-size: 16px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.active .sign-in {
  transform: translateX(100%);
}

.sign-up {
  background-color: #fff;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.active .sign-up {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: move 0.6s;
}

@keyframes move {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.social-icons {
  margin: 20px 0;
}

.social-icons a {
  border: 1px solid #ccc;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  width: 40px;
  height: 40px;
}

.toggle-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 150px 0 0 100px;
  z-index: 1000;
}

.container.active .toggle-container {
  transform: translateX(-100%);
  border-radius: 0 150px 100px 0;
}

.toggle {
  height: 100%;
  background: #1A1A1A;
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.container.active .toggle {
  transform: translateX(50%);
}

.toggle-panel {
  position: absolute;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.togle-left {
  transform: translateX(-200%);
}

.container.active .toggle-left {
  transform: translateX(0);
}

.toggle-right {
  right: 0;
  transform: translateX(0);
}

.container.active .toggle-right {
  transform: translateX(200%);
}

.close {
  display: flex;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 30px;
  background-color: #F5C518;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 8px;
}

.close-text {
  margin-top: 3px;
}


@media only screen and (max-width: 600px) {

  .container.active .sign-in,
  .container.active .sign-up,
  .container.active .toggle-container {
    transform: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
  }

  .sign-up {
    opacity: 1;
    margin-bottom: 20px;
  }

  .sign-in,
  .sign-up {
    width: 100%;
  }

  .toggle-container {
    width: 5%;
    left: 0;
  }

  .toggle-container p,
  .toggle-container h1,
  .toggle-container button {
    display: none;
  }

  .form-container {
    position: static;
  }


}