* {
    text-decoration: none;
}

.search-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    position: fixed;
    top: 57px;
    left: 0;
    z-index: 10;
    overflow: auto;
    scrollbar-width: none;
    max-height: calc(100vh - 57px);
}

.searched-movie {
    display: flex;
    align-items: flex-start;
    list-style-type: none;
    background-color: #1F1F1F;
    padding: 10px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
}

.searched-movie img {
    width: 48px;
    height: 71px;
    margin-right: 5px;
    object-fit: cover;
    object-position: center;
    margin: auto 10px;
}

.movie-details {
    display: flex;
    flex-direction: column;
}

.searched-movie h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 10px 0;
}

.searched-movie p {
    color: #AAAAAA;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 5px 0;
}

.nav-items-mobile {
    margin-right: 20px; 
}

.nav-items-desktop {
    margin: auto 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    flex: 1;
    max-width: 800px;
}

.nav-items-desktop div {
    width: 100%;
}

.nav-items-desktop input {
    border-radius: 5px 0 0 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 100%;
    height: 31px;
    line-height: 31px;
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
}

.nav-items-desktop input::placeholder {
    font-size: 14px;
    color: #A6A7A6;
    font-weight: 400;
}

.nav-items-desktop svg {
    fill: #757575;
    height: 21px;
    width: 30px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    padding: 5px;
    cursor: pointer;
}
